import React from 'react';
import '../styles/landingPage.css';

export default function Partners() {

  return (
    <section id="swap-section">
      <div className="container mx-auto w-[92%] md:p-24 text-white">
        <h1 className="text-lg md:text-2xl md:text-4xl font-semibold text-center my-8">
          Partners & Backers
        </h1>
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-4">
          <a href='https://alchemicinvestments.com/'>
          <div className="p-6 text-center border rounded-lg bg-transparent hoverable-2 flex flex-col justify-center items-center lg:w-38 h-32">
            {/* <div style={{ width: '100px', height: '50px' }}> */}
              <img src={require("../img/alchemic3.png")} alt="" />
            {/* </div> */}
          </div>
          </a>
          <a href='https://momoboard.com/'>
          <div className="p-6 text-center border rounded-lg bg-transparent hoverable-2 flex flex-col justify-center items-center lg:w-38 h-32">
            {/* <div style={{ width: '100px', height: '50px' }}> */}
              <img src={require("../img/board.png")} alt="" />
            {/* </div> */}
          </div>
          </a>
          <a href='https://www.coinvestor.co/'>
          <div className="p-6 text-center border rounded-lg bg-transparent hoverable-2 flex flex-col justify-center items-center lg:w-38 h-32">
            {/* <div style={{ width: '100px', height: '50px' }}> */}
              <img src={require("../img/coinvestor2.png")} alt="" />
            {/* </div> */}
          </div>
          </a>
          <a href='https://www.krustuniverse.com/'>
          <div className="p-6 text-center border rounded-lg bg-transparent hoverable-2 flex flex-col justify-center items-center lg:w-38 h-32">
            {/* <div style={{ width: '100px', height: '50px' }}> */}
              <img src={require("../img/krust2.png")} alt="" />
            {/* </div> */}
          </div>
          </a>
          {/* <a href='https://momoboard.com/'> */}
          <div className="p-6 text-center border rounded-lg bg-transparent hoverable-2 flex flex-col justify-center items-center lg:w-38 h-32">
            {/* <div style={{ width: '100px', height: '50px' }}> */}
              <img src={require("../img/partners1.png")} alt="" />
            {/* </div> */}
          </div>
          {/* </a> */}
          <a href='https://www.certik.com/ko'>
          <div className="p-6 text-center border rounded-lg bg-transparent hoverable-2 flex flex-col justify-center items-center lg:w-38 h-32">
            {/* <div style={{ width: '100px', height: '50px' }}> */}
              <img src={require("../img/certik2.png")} alt="" />
            {/* </div> */}
          </div>
          </a>
          <a href='https://sui.io/'>
          <div className="p-6 text-center border rounded-lg bg-transparent hoverable-2 flex flex-col justify-center items-center lg:w-38 h-32">
            {/* <div style={{ width: '100px', height: '50px' }}> */}
              <img src={require("../img/sui3.png")} alt="" />
            {/* </div> */}
          </div>
          </a>
          <a href='https://www.redrockcapital.io/'>
          <div className="p-6 text-center border rounded-lg bg-transparent hoverable-2 flex flex-col justify-center items-center lg:w-38 h-32">
            {/* <div style={{ width: '100px', height: '50px' }}> */}
              <img src={require("../img/redrock3.png")} alt="" />
            {/* </div> */}
          </div>
          </a>
          <a href='https://scvsoft.net/'>
          <div className="p-6 text-center border rounded-lg bg-transparent hoverable-2 flex flex-col justify-center items-center lg:w-38 h-32">
            {/* <div style={{ width: '100px', height: '50px' }}> */}
              <img src={require("../img/scv.png")} alt="" />
            {/* </div> */}
          </div>
          </a>
          <a href='https://polygonstudios.com/'>
          <div className="p-6 text-center border rounded-lg bg-transparent hoverable-2 flex flex-col justify-center items-center lg:w-38 h-32">
            {/* <div style={{ width: '100px', height: '50px' }}> */}
              <img src={require("../img/polygonstudio5.png")} alt="" />
            {/* </div> */}
          </div>
          </a>
        </div>
      </div>
    </section>
  );
}
