import React from "react";
import "../styles/landingPage.css";
import PieChart from "../img/bbgLPmanager.png";

export default function Section2() {
  return (
    <section id="section-2">
      <div className="container mx-auto w-[92%] py-8">
        <div className="text-white md:flex justify-around">
          <div className="flex flex-col justify-around items-center">
            <h1 className="font-extrabold py-10 text-4xl md:text-5xl">
              Ecosystem
            </h1>
            <img src={PieChart} alt="" />
          </div>
          <div className="flex flex-col justify-around items-center">
            <div className=" rounded-lg hoverable bg-[#333247] max-w-xl lg:p-6 p-4 my-2">
              <h3 className="text-2xl font-semibold my-4">BBG LP Manager</h3>
              <p>
                Maximize the capital efficiency of cryptocurrencies AI based Quantitative
                analysis of historical trade data
              </p>
            </div>
            <div className=" rounded-lg hoverable bg-[#333247] max-w-xl lg:py-6 lg:px-8 p-4 my-2">
              <h3 className="text-2xl font-semibold my-4">BBG Swap</h3>
              <p>
                Setting the optimal token exchange path User-friendly interface
              </p>
            </div>
            <div className=" rounded-lg hoverable bg-[#333247] max-w-xl lg:p-6 p-4 my-2">
              <h3 className="text-2xl font-semibold my-4">BBG CONNEC2</h3>
              <p>
                Provides Web2Provide custodial storage of points and tokens
                -based points and Web3 token swap functionality
              </p>
            </div>
            <div className=" rounded-lg hoverable bg-[#333247] max-w-xl lg:p-6 p-4 my-2">
              <h3 className="text-2xl font-semibold my-4">BBG Wallet</h3>
              <p>
                Wallet is the foundation of all Web3 dapps. Card Type Hardware
                Wallet
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
