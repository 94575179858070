import React from "react";
import "../styles/landingPage.css";
import Polygon from "../img/Polygon 1.png";
import RectOne from "../img/Group 17017.png";
import RectTwo from "../img/Group 17018.png";
import RectThree from "../img/Group 17019.png";

export default function Section3() {
  return (
    <section className=" pt-20 ">
      <div className="   ">
        {" "}
        <div className="text-white  md:flex  md:items-center flex-col  w-full py-10  ">
          <p className="font-extrabold text-center md:text-4xl text-3xl">
            Our Vision
          </p>
          <p className="lg:px-96 text-center lg:py-5 md:text-2xl text-xl text-gray-400 font-semibold md:px-20 px-5 sm:py-1">
            Our Vision Is To Maximize{" "}
            <span className="text-[#FBA45A]"> The Capital Efficiency </span> Of
            Cryptocurrencies And Contribute To The Mass Adoption Of Web3.
          </p>
        </div>
        {/* <div className="flex justify-center relative  ">
          <p className="font-extrabold text-white text-2xl absolute top-80  z-20 ">
            BBGO
          </p>
          <img
            src={Polygon}
            alt=""
            className="h-52	 w-64	 absolute top-56 z-10"
          />
          <img
            src={RectTwo}
            alt=""
            className="h-80	 w-80	 absolute top-80 right-1/2 "
          />
          <img
            src={RectOne}
            alt=""
            className="lg:h-80 md:h-56 h-48	 lg:w-80 md:w-56 w-48	"
          />

          <img
            src={RectThree}
            alt=""
            className="h-80	 w-80	 absolute top-80 left-1/2 "
          />
        </div> */}
        <div className="relative flex flex-col items-center">
          <div className="md:h-44 h-40 md:w-44 w-40 ">
            <img src={RectOne} alt="" className="w-full h-full" />
          </div>
          <div className=" relative md:h-36 h-32 md:w-44 w-40 -mt-10 z-20 ">
            <p className="text-white absolute top-16 md:mt-5 mt-3 font-bold left-[35%]">
              BEGO
            </p>
            <img src={Polygon} alt="" className="w-full h-full" />
          </div>
          <div className="flex -mt-20">
            <div className="md:h-56 h-52 md:w-56 w-52 ">
              <img src={RectTwo} alt="" className="w-full h-full" />
            </div>

            <div className="md:h-64 h-60 md:w-64 w-42 -mt-4 ">
              <img src={RectThree} alt="" className="w-full h-full" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
