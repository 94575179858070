// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/soon.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../img/soon-sm.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#coming-soon {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100vh;
}

@media (max-width: 425px) {
  #coming-soon {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: center !important;
    height: 100vh;
  }
}

.hover-btn {
  background: linear-gradient(135.61deg, #f7138f 0%, #fba45a 101.09%);
}
[placeholder] {
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/styles/comingSoon.css"],"names":[],"mappings":"AAAA;EACE,mDAAkC;EAClC,4BAA4B;EAC5B,wBAAwB;EACxB,2BAA2B;EAC3B,aAAa;AACf;;AAEA;EACE;IACE,8DAAgD;IAChD,uCAAuC;IACvC,mCAAmC;IACnC,sCAAsC;IACtC,aAAa;EACf;AACF;;AAEA;EACE,mEAAmE;AACrE;AACA;EACE,uBAAuB;AACzB","sourcesContent":["#coming-soon {\n  background: url('../img/soon.svg');\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n  height: 100vh;\n}\n\n@media (max-width: 425px) {\n  #coming-soon {\n    background: url('../img/soon-sm.svg') !important;\n    background-repeat: no-repeat !important;\n    background-size: contain !important;\n    background-position: center !important;\n    height: 100vh;\n  }\n}\n\n.hover-btn {\n  background: linear-gradient(135.61deg, #f7138f 0%, #fba45a 101.09%);\n}\n[placeholder] {\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
