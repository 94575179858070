import React from "react";
import "../styles/landingPage.css";

export default function Roadmap() {
  return (
    <section id="roadmap">
      <div className="h-full flex flex-col items-center justify-center py-12 md:py-24 container mx-auto w-[92%]">
        <h1 className="text-white text-center text-2xl md:text-4xl font-extrabold mb-8">
          Roadmap
        </h1>
        {/* laptop screen */}
        <div className="flex justify-around w-[65%] hidden md:flex">
          <img src={require("../img/roadmap-lg.svg").default} alt="" />
          <div>
            <div className="flex flex-col  items-between  h-full justify-between ">
              <div className="roadmap-card px-10 py-2 mb-8 lg:mt-10">
                <ul className="text-white font-semibold list-disc">
                  <li className="my-4">BBGSWAP Service Launch</li>
                  <li className="my-4">White paper Release</li>
                </ul>
              </div>
              <div className="roadmap-card px-10 py-2 my-8 lg:-mt-6">
                <ul className="text-white font-semibold list-disc">
                  <li className="my-4">BBG CONNEC2 Service Launch</li>
                  <li className="my-4">BBG LP MANAGER Service Launch</li>
                </ul>
              </div>
              <div className="roadmap-card px-10 py-2 my-8 lg:">
                <ul className="text-white font-semibold list-disc">
                  <li className="my-4">BBG WALLET Service Upgrade</li>
                </ul>
              </div>
              <div className="roadmap-card px-10 py-2 my-8">
                <ul className="text-white font-semibold list-disc">
                  <li className="my-4">BBG Ecosystem integration</li>
                </ul>
              </div>
              <div className="roadmap-card px-10 py-2 my-8 lg:mb-14">
                <ul className="text-white font-semibold list-disc">
                  <li className="my-4">BBG Ecosystem extension</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* mobile and tab screen */}
        <div className="roadmap-wrapper-sm flex md:hidden">
          <div
            className="flex flex-col items-center"
            style={{ minWidth: "100vw" }}
          >
            <img
              src={require("../img/q2-2023.svg").default}
              alt=""
              className="mb-4"
            />
            <div className="roadmap-card-sm px-10 py-2 ">
              <ul className="text-white font-semibold list-disc">
                <li className="my-2 text-sm">BBGSWAP Service Launch</li>
                <li className="my-2 text-sm">White Paper Release</li>
                {/* <li className="my-2 text-sm">Architecture design</li>
                <li className="my-2 text-sm">Seed Private sale</li> */}
              </ul>
            </div>
          </div>
          <div
            className="flex flex-col items-center"
            style={{ minWidth: "100vw" }}
          >
            <img
              src={require("../img/q3-2023.svg").default}
              alt=""
              className="mb-4"
            />
            <div className="roadmap-card-sm px-10 py-2 ">
              <ul className="text-white font-semibold list-disc">
                <li className="my-2 text-sm">BBG CONNEC2 Service Launch</li>
                <li className="my-2 text-sm">BBG LP MANAGER Service Launch</li>
                {/* <li className="my-2 text-sm">DEX Launchpad design</li>
                <li className="my-2 text-sm">Public sale</li> */}
              </ul>
            </div>
          </div>
          <div
            className="flex flex-col items-center"
            style={{ minWidth: "100vw" }}
          >
            <img
              src={require("../img/q4-2023.svg").default}
              alt=""
              className="mb-4"
            />
            <div className="roadmap-card-sm px-10 py-2 ">
              <ul className="text-white font-semibold list-disc">
                <li className="my-2 text-sm">
                BBG WALLET Service Upgrade
                </li>
                {/* <li className="my-2 text-sm">DEX Launchpad Launch</li>
                <li className="my-2 text-sm">Crypto Wallet design</li> */}
              </ul>
            </div>
          </div>
          <div
            className="flex flex-col items-center"
            style={{ minWidth: "100vw" }}
          >
            <img
              src={require("../img/q1-2024.svg").default}
              alt=""
              className="mb-4"
            />
            <div className="roadmap-card-sm px-10 py-2 ">
              <ul className="text-white font-semibold list-disc">
                <li className="my-2 text-sm">BBG Ecosystem integration</li>
                {/* <li className="my-2 text-sm">Crypto Wallet Launch</li>
                <li className="my-2 text-sm">Governance design</li> */}
              </ul>
            </div>
          </div>
          <div
            className="flex flex-col items-center"
            style={{ minWidth: "100vw" }}
          >
            <img
              src={require("../img/q2-2024.svg").default}
              alt=""
              className="mb-4"
            />
            <div className="roadmap-card-sm px-10 py-2 ">
              <ul className="text-white font-semibold list-disc">
                <li className="my-2 text-sm">BBG Ecosystem extension</li>
                {/* <li className="my-2 text-sm">Governance Protocol Launch</li>
                <li className="my-2 text-sm">Platform extension</li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

