import React from 'react';
import '../styles/landingPage.css';
import Box from '../img/Group16919.png'

export default function IDOSection() {
  return (
    <section id="ido-section">
      <div className="container w-[92%] mx-auto py-8 md:py-24">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex justify-center items-center">
            <img src={Box} alt="" />
          </div>
          <div className="text-white flex flex-col justify-center max-w-xl">
            <h1 className="text-2xl md:text-4xl font-extrabold my-8">
              What is BBG LP MANAGER?
            </h1>
            <p>
              BBG LP MANAGER recommends the optimal liquidity supply pool
              through quantitative analysis of transaction data based on the
              provide liquidity amount, expected return, provide liquidity
              appetite, etc. entered by the user.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
